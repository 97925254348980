<template>
  <div class="vx-row">
    <!-- Fond de caisse -->
    <vs-popup fullscreen title="Fond de caisse exercice" :active.sync="popopFondDeCaisse">
      <div class="vx-row">
        <div class="vx-col w-full">
          <vx-card :noShadow="true" :cardBorder="true">
            <div class="vx-row">
              <div class="vx-col md:w-1/5">
                <div class="">
                  <label for="DateDebutExercice" class="text-sm">Date début d'exercice</label>
                  <flat-pickr v-model="DateDebutExercice" :config="{ dateFormat: 'd F Y'}" class="w-full" name="DateDebutExercice" />
                </div>
              </div>
              <div class="vx-col md:w-1/5">
                <div class="">
                  <label for="DateFinExercice" class="text-sm">Date fin d'exercice</label>
                  <flat-pickr v-model="DateFinExercice" :config="{ dateFormat: 'd F Y'}" class="w-full" name="DateFinExercice" />
                </div>
              </div>
              <div class="vx-col md:w-1/5">
                <vs-input class="w-full" min="0"  type="number" label="Montant"  name="MontFond" :value="MontFond" @change.native="MontFond=$event.target.value" />
              </div>
              <div class="vx-col md:w-1/5">
                <vs-input class="w-full" type="text" label="Observation"  name="MontObservation" :value="MontObservation" @change.native="MontObservation=$event.target.value" />
              </div>

              <div class="vx-col md:w-1/5 mt-5">
                <vs-button v-if="(DateDebutExercice !== null || DateDebutExercice) && (DateFinExercice !== null || DateFinExercice) && (MontFond !== null || MontFond) " color="primary" class="w-full" @click="addFondDeCaisse">Enregistrer</vs-button>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
      <div class="vx-row mt-4">
        <div class="vx-col w-full">
          <vx-card :noShadow="true" :cardBorder="true">
            <vs-table stripe pagination max-items="10" search :data="fondDeCaisseData">
              <template slot="thead">
                <vs-th>
                  N°
                </vs-th>
                <vs-th>Date début exercice</vs-th>
                <vs-th>Date fin exercice</vs-th>
                <vs-th>Date initialisation</vs-th>
                <vs-th>Montant</vs-th>
                <vs-th>Status</vs-th>
                <vs-th>Actions</vs-th>
              </template>

              <template slot-scope="{data}">
                <tbody>
                  <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                    <vs-td>
                      {{indextr + 1}}
                    </vs-td>
                    <vs-td>
                      {{tr.DateDebutExercice | moment("calendar", "July 10 2011") }}
                    </vs-td>
                    <vs-td>
                      {{tr.DateFinExercice | moment("calendar", "July 10 2011") }}
                    </vs-td>
                    <vs-td>
                      {{(tr.DateAppliquerPourInitialiserCaisseRecette ? tr.DateAppliquerPourInitialiserCaisseRecette : '') | moment("calendar", "July 10 2011") }}
                    </vs-td>
                    <vs-td>
                      {{moneyFormatter(tr.MontFond)}}
                    </vs-td>
                    <vs-td>
                      <template v-if="tr.status && tr.status === true">
                        <vs-chip transparent color="success">
                          Caisse recette initialisation
                        </vs-chip>
                      </template>
                      <template v-else>
                        <vs-chip transparent color="danger">
                          Non initialisation
                        </vs-chip>
                      </template>
                    </vs-td>
                    <vs-td>
                      <!-- <i data-feather='toggle-left'></i> -->
                      <feather-icon v-if="!tr.status" title="Initialisation la caisse recette" icon="ToggleLeftIcon" svgClasses="w-5 h-5 hover:text-primary cursor-pointer" @click.stop="initialisationCaisse(tr)" />
                      <feather-icon v-if="!tr.status" icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer ml-2" @click="deleteFondDeCaisse(tr._id)" />
                    </vs-td>

                  </vs-tr>
                </tbody>
              </template>
            </vs-table>
          </vx-card>
        </div>
      </div>
    </vs-popup>

    <!-- Consultation -->
    <vs-popup title="Consultation" :active.sync="popupConsultation">
      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-input class="w-full mt-4" type="text" label="Patient"  name="Patient" :value="Patient" @change.native="Patient=$event.target.value" />
        </div>
        <div class="vx-col w-full">
          <vs-input class="w-full mt-4" type="number" nim="0" label="Montant"  name="MontantConsultation" :value="MontantConsultation" @change.native="MontantConsultation=$event.target.value" />
        </div>
        <div class="vx-col w-full">
          <vs-textarea class="mt-4" label="Observation" :value="ObservationConsultation" @change.native="ObservationConsultation=$event.target.value" />
        </div>
      </div>
      <div class="vx-row mt-4">
        <div class="vx-col w-full text-center">
          <vs-button v-if="(CaisseIsInit)" class="ml-auto mt-2" @click="makeConsultation">Enregistrer</vs-button>
          <vs-button class="ml-4 mt-2" type="border" color="warning" @click="Patient='';MontantConsultation=null;popupConsultation=false">Annuler</vs-button>
        </div>
      </div>
    </vs-popup>
    <!-- Vente au comptoire -->
    <vs-popup fullscreen title="Vente au comptoire" :active.sync="popopVenteComptoire">
      <div class="vx-row mt-4">
        <div class="vx-col w-full">
          <vs-table multiple v-model="venteComptoires" pagination max-items="10" search :data="getArticleWhereQtyIsSupThanZero">
            <template slot="header">
              <!-- ACTION - DROPDOWN -->
              <vs-select  placeholder="Client" autocomplete class="selectExample select-large" v-model="clientId" @change="getCommandeOnSelectClient(clientId)">
                <vs-select-item :key="index" :value="item._id" :text="`${item.Nom} ${item.Prenoms}`" v-for="(item,index) in clientsData" />
              </vs-select>

              <vs-button v-if="this.venteComptoires.length > 0" class="mb-4 ml-5 md:mb-0" @click="VenteAuComptoireTotal()">Valider</vs-button>
            </template>
            <template slot="thead">
              <vs-th sort-key="Etiquette">
                Etiquette
              </vs-th>
              <vs-th>Marque</vs-th>
              <vs-th sort-key="Modele">Modèle</vs-th>
              <vs-th>Catégorie</vs-th>
              <vs-th>Qté</vs-th>
              <vs-th>Prix de vente HT</vs-th>
              <vs-th>Prix de vente TTC</vs-th>
              <vs-th>Prix planché</vs-th>
              <vs-th>Couleur</vs-th>
              <vs-th>Type</vs-th>
              <vs-th>Taille</vs-th>
              <vs-th>Rémise</vs-th>
            </template>

            <template slot-scope="{data}">
              <tbody>
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                  <vs-td>
                    {{tr.Etiquette}}
                  </vs-td>
                  <vs-td>
                    {{getMarqueName(tr.IdMarque) }}
                  </vs-td>
                  <vs-td>
                    {{tr.Modele }}
                  </vs-td>
                  <vs-td>
                    {{getCategorieArticleById(tr.IdCateg)}}
                  </vs-td>
                  <vs-td :data="tr.stock">
                    {{tr.stock}}
                    <template slot="edit">
                      <vs-input class="w-full mt-4" type="number" :max="tr.stock" label="Qté"  name="stock" v-model="tr.stock" />
                    </template>
                  </vs-td>
                  <vs-td :data="tr.PrixVenteHT">
                    {{moneyFormatter(tr.PrixVenteHT)}}
                    <template slot="edit">
                      <vs-input class="w-full mt-4" type="number" :min="tr.PrixPlanche" label="Prix Vente HT"  name="PrixVenteHT" v-model="tr.PrixVenteHT" />
                    </template>
                  </vs-td>
                  <vs-td>
                    {{moneyFormatter(tr.PrixVenteTTC)}}
                  </vs-td>
                  <vs-td>
                    {{moneyFormatter(tr.PrixPlanche)}}
                  </vs-td>
                  <vs-td>
                    {{tr.Couleur}}
                  </vs-td>
                  <vs-td>
                    {{getTypeById(tr.IdTypeArt)}}
                  </vs-td>
                  <vs-td>
                    {{tr.Taille[0].Hauteur}}/{{tr.Taille[0].Nez}}/{{tr.Taille[0].Longeur}}
                  </vs-td>
                  <vs-td :data="tr.Remise">
                    {{tr.Remise}}
                    <template slot="edit">
                      <vs-input class="w-full mt-4" type="number" label="Rémise"  name="Remise" v-model="tr.Remise" />
                    </template>
                  </vs-td>


                </vs-tr>
              </tbody>
            </template>
          </vs-table>
        </div>
      </div>
    </vs-popup>

    <vs-popup title="Règlement commande comptoire" :active.sync="popopReglcmdComptoire">
      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-alert color="danger" title="Alert" :active="alert">
            Montant de {{montantSaisie}} saisie supérieur au montant dû
          </vs-alert>
          <vs-alert color="danger" title="Alert" :active="alertMontantrecu">
            Saisissez le montant Reçu avant le montant à reglé
          </vs-alert>
          <vs-alert color="danger" title="Alert" :active="alertMontantSup">
           le montant à reglé saisie est supérieur au montant réçu
          </vs-alert>
        </div>
        <div class="vx-col w-full">
          <vs-select label="Mode de payment" autocomplete class="selectExample w-full select-large" v-model="modeRegl">
            <vs-select-item :key="index" :value="item.libelle" :text="item.value" v-for="(item,index) in moyen_paiement" />
          </vs-select>
        </div>
        <div class="vx-col w-full" v-if="modeRegl === 'Chèque'">
          <vs-input class="w-full mt-4" type="text" label="N° chèque"  name="NumCheque" :value="NumCheque" @change.native="NumCheque=$event.target.value" />
        </div>
        <div class="vx-col md:w-1/2">
          <vs-input class="w-full mt-4" min="0" disabled type="number" label="Montant a reglé"  name="MontantDu" :value="PartClientComptoire" @change.native="PartClientComptoire=$event.target.value" />
        </div>
        <div class="vx-col md:w-1/2">
          <vs-input class="w-full mt-4" min="0" :max="MontantDu" type="number" label="Règlement"  name="Montant" :value="Montant" @change.native="Montant=$event.target.value;venteAUComptoireCalculMonnaie()"/>
        </div>
        <div class="vx-col md:w-1/2">
          <vs-input class="w-full mt-4" min="0" type="number" label="Montant Reçu"  name="MontantRecu" v-model="MontantRecu" :value="MontantRecu" @change.native="MontantRecu=$event.target.value" />
        </div>
        <div class="vx-col md:w-1/2">
          <vs-input class="w-full mt-4" min="0" disabled type="number" label="Monnaie"  name="Monnaie" v-model="Monnaie" />
        </div>
        <div class="vx-col w-full mt-4">
          <vs-button color="primary" class="w-full" @click="reglementCmdComptoire">Valider</vs-button>
        </div>
      </div>
    </vs-popup>

    <!-- Règlement client -->
    <vs-popup title="Commandes" :active.sync="popupSelectCommande">

      <div class="vx-row">
        <div class="vx-col w-full">

          <!-- <vs-select label="Client" autocomplete class="selectExample w-full select-large" v-model="clientId" @change="getCommandeOnSelectClient(clientId)">
            <vs-select-item :key="index" :value="item._id" :text="`${item.Nom} ${item.Prenoms}`" v-for="(item,index) in clientsData" />
          </vs-select> -->
        </div>

        <div class="vx-col w-full">
          <vs-select label="Commandes" autocomplete class="selectExample w-full select-large" v-model="RefCmmd" @change="ShowCommandeDetailForRegl(RefCmmd)">
            <vs-select-item :key="index" :value="item.RefCmmd" :text="item.RefCmmd" v-for="(item,index) in commandes_client_all" />
          </vs-select>
        </div>
      </div>
    </vs-popup>

    <vs-popup title="Règlement commande Client" :active.sync="popupSelectRegleClient">
      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-alert color="danger" title="Alert" :active="alert">
            Montant de {{montantSaisie}} saisie supérieur au montant dû
          </vs-alert>
          <vs-alert color="danger" title="Alert" :active="alertMontantrecu">
            Saisissez le montant Reçu avant le montant à reglé
          </vs-alert>
          <vs-alert color="danger" title="Alert" :active="alertMontantSup">
           le montant à reglé saisie est supérieur au montant réçu
          </vs-alert>
        </div>
        <div class="vx-col w-full">
          <vs-select label="Mode de payment" autocomplete class="selectExample w-full select-large" v-model="modeRegl">
            <vs-select-item :key="index" :value="item.libelle" :text="item.value" v-for="(item,index) in moyen_paiement" />
          </vs-select>
        </div>

        <div class="vx-col md:w-1/2">
          <vs-input class="w-full mt-4" min="0" disabled type="number" label="Montant dû"  name="MontantDu" :value="MontantDu" @change.native="MontantDu=$event.target.value" />
        </div>
        <div class="vx-col md:w-1/2">

          <vs-input class="w-full mt-4" min="0" :max="MontantDu" type="number" label="Règlement"  name="Montant" :value="Montant" @change.native="Montant=$event.target.value;onReglementSupMontantDu(Montant)" />
        </div>
        <div class="vx-col md:w-1/2">
          <vs-input class="w-full mt-4" min="0" type="number" label="Montant Reçu"  name="MontantRecu" :value="MontantRecu" @change.native="MontantRecu=$event.target.value;onChangeMontantRecu()" />
        </div>
        <div class="vx-col md:w-1/2">
          <vs-input class="w-full mt-4" min="0" disabled type="number" label="Monnaie"  name="Monnaie" v-model="Monnaie" />
        </div>
        <div class="vx-col w-full">
          <vs-input class="w-full mt-4"  type="text" label="Remarque"  name="remarque" :value="remarque" @change.native="remarque=$event.target.value" />
        </div>
        <div class="vx-col w-full mt-4">
          <vs-button color="primary" class="w-full" @click="makeReglementClient">VALIDER</vs-button>
        </div>
      </div>
    </vs-popup>
    <!-- end règlement client -->

    <!-- client recu sidebar view -->
    <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveRecuOperation">
      <div class="mt-6 flex items-center justify-between px-6">
        <h4>Historique de règlement commande : <b>{{ RefCmmd }}</b></h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveRecuOperation = false" class="cursor-pointer"></feather-icon>
      </div>
      <div class="px-6">
        <b>{{ model }}</b>
      </div>
      <vs-divider class="mb-0"></vs-divider>
      <div v-if="reglement_clients" class="my-10 px-6">
        <div class="w-full">
          <div>
            <b>Total cmmd. : </b> <span>{{moneyFormatter(totalCmdClient ? totalCmdClient : 0)}}</span>
          </div>
          <div>
            <b>Remise : </b> <span>{{moneyFormatter(remiseCmdClient ? remiseCmdClient : 0)}}</span>
          </div>
          <div>
            <b>Total Net : </b> <span>{{moneyFormatter(totalNetCmdClient ? totalNetCmdClient : 0)}}</span>
          </div>
          <div>
            <b>Part assurance : </b> <span>{{moneyFormatter(partAssu ? partAssu : 0)}}</span>
          </div>
          <div>
            <b>Part client : </b> <span>{{moneyFormatter(partClient ? partClient : 0)}}</span>
          </div>
          <div>
            <b>Encaissé : </b> <span>{{moneyFormatter(encaisse ? encaisse : 0)}}</span>
          </div>
          <div class="flex">
            <b>Solde : </b> <span class="bg-primary px-1 mx-1" style="color: white">{{moneyFormatter(totalNetCmdClient - encaisse)}} {{ devise }}</span>
          </div>
        </div>
        <vs-table class="mt-2" stripe noDataText="Aucun règlement" :data="reglement_clients">
          <template slot="thead">
            <vs-th sort-key="DateRegle">Date</vs-th>
            <vs-th sort-key="Montant">Montant Regl.</vs-th>
            <vs-th sort-key="modeRegl">Mode</vs-th>
            <vs-th></vs-th>
          </template>

          <template slot-scope="{data}">
            <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  {{tr.DateRegle  | moment("calendar", "July 10 2011")}}
                </vs-td>
                <vs-td>
                  {{moneyFormatter(tr.Montant)}}
                </vs-td>
                <vs-td>
                  {{tr.modeRegl}}
                </vs-td>
                <vs-td>
                  <span class="hover:text-primary" @click="generateReport(indextr)">Imprimer</span>
                </vs-td>

              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>
    </vs-sidebar>

    <!-- / client recu sidebar view -->

    <!-- Versement -->
    <vs-popup fullscreen title="Versement" :active.sync="popoupVersement">
      <div class="vx-row">
        <div class="vx-col md:w-1/3">
          <div class="vx-row">
            <div class="vx-col w-full">
              <vs-select label="Banque" autocomplete class="selectExample w-full select-large" v-model="Banque">
                <vs-select-item :key="index" :value="item.NomBanque" :text="item.NomBanque" v-for="(item,index) in BanqueData" />
              </vs-select>
            </div>
            <div class="vx-col md:w-1/2 mt-5">
              <vs-input class="w-full" min="0"  type="number" label="Montant"  name="MontantDeposer" :value="MontantDeposer" @change.native="MontantDeposer=$event.target.value" />
            </div>
            <div class="vx-col md:w-1/2 mt-5">
              <vs-input class="w-full"  type="text" label="Coursier"  name="Coursier" :value="Coursier" @change.native="Coursier=$event.target.value" />
            </div>
            <div class="vx-col w-full mt-5">
              <vs-textarea label="Observation" :value="ObservationVersement" @change.native="ObservationVersement=$event.target.value" />
            </div>
            <div class="vx-col w-full mt-5">
              <vs-checkbox v-model="verCaisseDepense">Vers caisse dépense</vs-checkbox>
            </div>

            <div class="vx-col w-full mt-5">
              <div class="flex flex-wrap items-center justify-end">
                <vs-button v-if="(CaisseIsInit && !billetageOnSelectDay)" class="ml-auto" @click="makeVersementBanque">Valider</vs-button>
                <vs-button v-if="(CaisseIsInit && !billetageOnSelectDay)" class="ml-4" type="border" color="warning" @click="popoupVersement=false">Annuler</vs-button>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-col md:w-2/3">
          <vs-table stripe pagination max-items="15" search :data="versementsData">
            <template slot="thead">
              <vs-th>
                N°
              </vs-th>
              <vs-th sort-key="DateDepot">Date</vs-th>
              <vs-th sort-key="Banque">Banque</vs-th>
              <vs-th sort-key="MontantDeposer">Montant</vs-th>
              <vs-th sort-key="IdUser">Coursier</vs-th>
              <vs-th sort-key="IdUser">Observation</vs-th>
              <vs-th sort-key="IdUser">Status</vs-th>
              <vs-th sort-key="IdUser">Actions</vs-th>
            </template>

            <template slot-scope="{data}">
              <tbody>
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                  <vs-td>
                    {{indextr + 1}}
                  </vs-td>
                  <vs-td>
                    {{tr.DateDepot | moment("calendar", "July 10 2011") }}
                  </vs-td>
                  <vs-td>
                    {{tr.Banque }}
                  </vs-td>
                  <vs-td>
                    {{moneyFormatter(tr.MontantDeposer)}}
                  </vs-td>
                  <vs-td>
                    {{tr.Coursier }}
                  </vs-td>

                  <vs-td>
                    {{tr.Observation }}
                  </vs-td>

                  <vs-td>
                    <template v-if="tr.versCaisseDepense">
                      <vs-chip transparent color="success">
                          vers caisse depense
                        </vs-chip>
                    </template>
                    <template v-else>
                      <template v-if="tr.status">
                        <vs-chip transparent color="success">
                          valider
                        </vs-chip>
                      </template>
                      <template v-else>
                        <vs-chip transparent color="danger">
                          Non valider
                        </vs-chip>
                      </template>
                    </template>
                  </vs-td>
                  <vs-td>
                    <feather-icon v-if="!tr.versCaisseDepense && !tr.status" title="Valider le versement" icon="CheckCircleIcon" svgClasses="w-5 h-5 hover:text-grey stroke-current ml-4" @click.stop="ValidVersement(tr._id)" />
                    <feather-icon title="Archiver" icon="ArchiveIcon" svgClasses="w-5 h-5 hover:text-grey stroke-current ml-4" @click.stop="ArchiveVersement(tr._id)" />
                  </vs-td>

                </vs-tr>
              </tbody>
            </template>
          </vs-table>
        </div>
      </div>
    </vs-popup>

    <!-- Cloture -->
    <vs-popup fullscreen title="Cloture de caisse" :active.sync="popupCloture">
      <div class="vx-row">
        <div class="vx-col w-full">
          <h3>Date: {{dateCaisse | moment("calendar", "July 10 2011") }}</h3>
        </div>
      </div>
      <vs-divider />
      <div class="vx-row">
        <div class="vx-col md:w-2/3">
          <div class="vx-row">
            <div class="vx-col w-full">
              <vx-card :noShadow="true" :cardBorder="true">
                <div class="vx-row mt-2">
                  <div class="vx-col bg-primary mr-2">
                    <h5 style="color: white">Mouv</h5>
                  </div>
                  <div class="vx-col bg-black mr-2">
                    <h5 style="color: white">TOTAL SORTIE:{{moneyFormatter(MontantDecaisse)}}</h5>
                  </div>
                  <div class="vx-col bg-black mr-2">
                    <h5 style="color: white">TOTAL ENTREES: {{moneyFormatter(EncaisseSelectDate)}}</h5>
                  </div>
                  <div class="vx-col bg-primary mr-2">
                    <h5 style="color: white">Chèq</h5>
                  </div>
                  <div class="vx-col bg-black mr-2">
                    <h5 style="color: white">Mont Chèq reçu: {{moneyFormatter(MontantCheqRecu)}}</h5>
                  </div>
                  <div class="vx-col bg-black mr-2">
                    <h5 style="color: white">Mont Chèq emis: {{moneyFormatter(MontantCheqEmis)}}</h5>
                  </div>
                </div>
              </vx-card>
            </div>
            <div class="vx-col w-full mt-2">
              <vx-card :noShadow="true" :cardBorder="true">
                <vs-table pagination max-items="10" :data="operationsToDayData">
                  <template slot="thead">
                    <vs-th sort-key="Date">
                      Date
                    </vs-th>
                    <vs-th sort-key="modepaiement">Moyen</vs-th>
                    <vs-th sort-key="pu">Caissier</vs-th>
                    <vs-th sort-key="typeOperation">Opération</vs-th>
                    <vs-th sort-key="montant">Montant</vs-th>
                  </template>

                  <template slot-scope="{data}">
                    <tbody>
                      <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                        <vs-td>
                          {{tr.dateOperation  | moment("calendar", "July 10 2011")}}
                        </vs-td>
                        <vs-td>
                          {{tr.modepaiement}}
                        </vs-td>
                        <vs-td>
                          {{getUserById(tr.IdUser).nom}} {{getUserById(tr.IdUser).prenoms}}
                        </vs-td>
                        <vs-td>
                          {{tr.typeOperation}}
                        </vs-td>
                        <vs-td>
                          {{tr.montant ? moneyFormatter(tr.montant) : 0}}
                        </vs-td>

                      </vs-tr>
                    </tbody>
                  </template>
                </vs-table>
              </vx-card>
            </div>
            <div class="vx-col w-full mt-2">
              <vx-card :noShadow="true" :cardBorder="true">
                <div class="vx-row mt-2">
                  <div class="vx-col md:w-1/3">
                    <h4 class="bg-primary mt-4 p-2" style="color: white">Observation</h4>
                  </div>
                  <div class="vx-col md:w-2/3">
                    <vs-input class="w-full mt-4"  type="text"   name="Observation" v-model="Observation" />
                  </div>
                </div>
              </vx-card>
            </div>

          </div>
        </div>
        <div class="vx-col md:w-1/3">
          <vx-card :noShadow="true" :cardBorder="true">
            <div class="vx-row">
              <div class="vx-col bg-primary mr-2">
                <h5 style="color: white">EN CAISSE</h5>
              </div>
              <div class="vx-col bg-black mr-2">
                <h5 style="color: white">{{moneyFormatter(totalEncaisse)}}</h5>
              </div>
            </div>
            <div class="vx-row mt-2">
              <div class="vx-col w-full bg-primary text-center">
                <span style="color: white">BILLETAGE</span>
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col w-full">
                <div class="vx-row">
                  <div class="vx-col w-full mt-4">
                    <div class="vx-row">
                      <div class="vx-col md:w-1/3 text-right pt-2">
                        10000 X
                      </div>
                      <div class="vx-col md:w-2/3">
                        <div class="vx-row">
                          <div class="vx-col md:w-1/2">
                            <vs-input-number v-model="nbreDixMil" @change="totalDixMil" />
                          </div>
                          <div class="vx-col md:w-1/2 pt-2">
                            = {{moneyFormatter(MontDixMil)}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="vx-row">
                      <div class="vx-col md:w-1/3 text-right pt-2">
                        5000 X
                      </div>
                      <div class="vx-col md:w-2/3">
                        <div class="vx-row">
                          <div class="vx-col md:w-1/2">
                            <vs-input-number v-model="nbreCqMil" @change="totalCqMil" />
                          </div>
                          <div class="vx-col md:w-1/2 pt-2">
                            = {{moneyFormatter(MontCqMil)}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="vx-row">
                      <div class="vx-col md:w-1/3 text-right pt-2">
                        2000 X
                      </div>
                      <div class="vx-col md:w-2/3">
                        <div class="vx-row">
                          <div class="vx-col md:w-1/2">
                            <vs-input-number v-model="nbreDeuxMil" @change="totalDeuxMil" />
                          </div>
                          <div class="vx-col md:w-1/2 pt-2">
                            = {{moneyFormatter(MontDeuxMil)}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="vx-row">
                      <div class="vx-col md:w-1/3 text-right pt-2">
                        1000 X
                      </div>
                      <div class="vx-col md:w-2/3">
                        <div class="vx-row">
                          <div class="vx-col md:w-1/2">
                            <vs-input-number v-model="nbreMil" @change="totalMil" />
                          </div>
                          <div class="vx-col md:w-1/2 pt-2">
                            = {{moneyFormatter(MontMil)}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="vx-row">
                      <div class="vx-col md:w-1/3 text-right pt-2">
                        500 X
                      </div>
                      <div class="vx-col md:w-2/3">
                        <div class="vx-row">
                          <div class="vx-col md:w-1/2">
                            <vs-input-number v-model="nbreCqCnt" @change="totalCqCnt" />
                          </div>
                          <div class="vx-col md:w-1/2 pt-2">
                            = {{moneyFormatter(MontCqCnt)}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="vx-row">
                      <div class="vx-col md:w-1/3 text-right pt-2">
                        250 X
                      </div>
                      <div class="vx-col md:w-2/3">
                        <div class="vx-row">
                          <div class="vx-col md:w-1/2">
                            <vs-input-number v-model="nbreDeuxCntCq" @change="totalDeuxCntCqt" />
                          </div>
                          <div class="vx-col md:w-1/2 pt-2">
                            = {{moneyFormatter(MontDeuxCntCq)}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="vx-row">
                      <div class="vx-col md:w-1/3 text-right pt-2">
                        200 X
                      </div>
                      <div class="vx-col md:w-2/3">
                        <div class="vx-row">
                          <div class="vx-col md:w-1/2">
                            <vs-input-number v-model="nbreDeuxCnt" @change="totalDeuxCnt" />
                          </div>
                          <div class="vx-col md:w-1/2 pt-2">
                            = {{moneyFormatter(MontDeuxCnt)}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="vx-row">
                      <div class="vx-col md:w-1/3 text-right pt-2">
                        100 X
                      </div>
                      <div class="vx-col md:w-2/3">
                        <div class="vx-row">
                          <div class="vx-col md:w-1/2">
                            <vs-input-number v-model="nbreCnt" @change="totalCnt" />
                          </div>
                          <div class="vx-col md:w-1/2 pt-2">
                            = {{moneyFormatter(MontCnt)}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="vx-row">
                      <div class="vx-col md:w-1/3 text-right pt-2">
                        50 X
                      </div>
                      <div class="vx-col md:w-2/3">
                        <div class="vx-row">
                          <div class="vx-col md:w-1/2">
                            <vs-input-number v-model="nbreCqt" @change="totalCqt" />
                          </div>
                          <div class="vx-col md:w-1/2 pt-2">
                            = {{moneyFormatter(MontCqt)}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="vx-row">
                      <div class="vx-col md:w-1/3 text-right pt-2">
                        25 X
                      </div>
                      <div class="vx-col md:w-2/3">
                        <div class="vx-row">
                          <div class="vx-col md:w-1/2">
                            <vs-input-number v-model="nbreVingtCinq" @change="totalVingtCinq" />
                          </div>
                          <div class="vx-col md:w-1/2 pt-2">
                            = {{moneyFormatter(MontVingtCinq)}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="vx-row">
                      <div class="vx-col md:w-1/3 text-right pt-2">
                        10 X
                      </div>
                      <div class="vx-col md:w-2/3">
                        <div class="vx-row">
                          <div class="vx-col md:w-1/2">
                            <vs-input-number v-model="nbreDix" @change="totalDix" />
                          </div>
                          <div class="vx-col md:w-1/2 pt-2">
                            = {{moneyFormatter(MontDix)}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="vx-row">
                      <div class="vx-col md:w-1/3 text-right pt-2">
                        5 X
                      </div>
                      <div class="vx-col md:w-2/3">
                        <div class="vx-row">
                          <div class="vx-col md:w-1/2">
                            <vs-input-number v-model="nbreCinq" @change="totalCinq" />
                          </div>
                          <div class="vx-col md:w-1/2 pt-2">
                            = {{moneyFormatter(MontCinq)}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </vx-card>
          <vx-card :noShadow="true" :cardBorder="true" class="mt-2">
            <div class="vx-row">
              <div class="vx-col md:w-1/3 text-right">
                TOTAL
              </div>
              <div class="vx-col md:w-2/3 bg-black text-right">
                <h4 style="color: white"> {{moneyFormatter(totalCaisse)}} </h4>
              </div>
            </div>
            <div class="vx-row mt-2">
              <div class="vx-col md:w-1/3 text-right">
                ECART
              </div>
              <div class="vx-col md:w-2/3 bg-black text-right">
                <h4 style="color: white">{{moneyFormatter((totalEncaisse - totalCaisse))}}</h4>
              </div>
            </div>
            <div class="vx-row mt-2">
              <!-- <feather-icon icon="AlertTriangleIcon" svgClasses="w-10 h-10 text-danger"></feather-icon>
              <span ></span> -->
              <vs-alert :active="!CaisseIsInit" class="" color="danger" icon-pack="feather" icon="icon-alert-triangle">
                Attention veuillez initialiser la caisse du jour avant la clôture !
              </vs-alert>
            </div>
          </vx-card>
        </div>
      </div>
      <!-- Save & Reset Button -->
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <vs-button v-if="(CaisseIsInit && !billetageOnSelectDay)" class="ml-auto mt-2" @click="makeClotureCaisseRecette">Clôture</vs-button>
            <vs-button v-if="(CaisseIsInit && !billetageOnSelectDay)" class="ml-4 mt-2" type="border" color="warning" @click="resetClotureData">Annuler</vs-button>
          </div>
        </div>
      </div>

    </vs-popup>

    <!-- <vs-popup title="Initialisation de la caisse" :active.sync="popupformInitialisationCaisse">
      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-input class="w-full" type="text" label="Montant"  name="montantInitialisationCaisse" v-model="montantInitialisationCaisse" />
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-center">
            <vs-button v-if="montantInitialisationCaisse" class="ml-auto mt-2" @click="initialisationCaisse">INITALISER</vs-button>
            <vs-button class="ml-4 mt-2" type="border" color="warning" @click="montantInitialisationCaisse = 0;popupformInitialisationCaisse=false">ANNULER</vs-button>
          </div>
        </div>
      </div>
    </vs-popup> -->

    <!-- PDF Generator -->
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        filename="reçu"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a5"
        pdf-orientation="portrait"
        pdf-content-width="95%"

        ref="html2PdfRecu"
      >
        <section style="padding-left: 30px;padding-top: 10px;" slot="pdf-content">
          <div class="vx-row" style="margin-top: 100px">
            <div class="vx-col md:w-2/3">
              {{DateRegle  | moment("calendar", "July 10 2011")}}
              <!-- {{Date.now() | moment("calendar", "July 10 2011") }}  -->
              <br>
              <!-- N°Reçu: <br> -->
              <b>Montant versé par: </b>{{ selected ? `${selected.Nom} ${selected.Prenoms}` : '' }}
            </div>
            <div class="vx-col md:w-1/3 text-right">
              {{timestamp | moment("HH:mm:ss") }}
            </div>
          </div>
          <table class="w-full mt-5 Table" cellpadding="0" cellspacing="0">
              <thead>
                <tr class="w-full">
                  <th class="thTable text-center" style="border-right-width: 0px; padding: 2px!important;width: 1%;"  colspan="1">Qte</th>
                  <th class="thTable text-center" style="border-right-width: 0px; padding: 2px!important;width: 49%;" colspan="4">Libelle</th>
                  <th class="thTable text-center" style="border-right-width: 0px; padding: 2px!important;width: 25%;" colspan="1">PrixU</th>
                  <th class="thTable text-center" style="padding: 2px!important;width: 25%;" colspan="2">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr style="padding: 2px" :key="indextr" v-for="(tr, indextr) in cmd_a_regle">
                  <td class="tdTable" style="padding: 2px!important;width: 1%;" colspan="1">{{tr.Qte}}</td>
                  <td class="tdTable" style="padding: 2px!important;width: 49%;" colspan="4">{{tr.Libelle}}</td>
                  <td class="text-right tdTable" style="padding: 2px!important;width: 25%;" colspan="1">{{moneyFormatter(+tr.PU)}}</td>
                  <td class="text-right tdTable" style="padding: 2px!important;width: 25%;" colspan="1">{{moneyFormatter(+tr.PT)}}</td>
                </tr>
                <tr style="padding: 2px">
                  <td class="tdTable" style="border: none; padding: 2px!important;width: 50%;" colspan="5"></td>
                  <td class="text-right tdTable" style="border-right: none;border-bottom: none;padding: 2px!important;width: 25%;" colspan="1"><b>Total Facture</b></td>
                  <td class="text-right tdTable" style="border-bottom: none;padding: 2px!important;width: 25%;" colspan="1">{{moneyFormatter(totalCmdClient)}}</td>
                </tr>
                <tr style="padding: 2px">
                  <td class="tdTable" style="border: none;padding: 2px!important;width: 50%;" colspan="5"></td>
                  <td class="text-right tdTable" style="border-right: none;border-bottom: none;padding: 2px!important;width: 25%;" colspan="1"><b>Remise</b></td>
                  <td class="text-right tdTable" style="border-bottom: none;padding: 2px!important;width: 25%;" colspan="1">{{moneyFormatter(remiseCmdClient)}}</td>
                </tr>
                <tr style="padding: 2px">
                  <td class="tdTable" style="border: none;padding: 2px!important;width: 50%;" colspan="5">Réglement de la cmmd N°{{  cmd_a_regle.length > 0 ? cmd_a_regle[0].Ref : '' }}</td>
                  <td class="text-right tdTable" style="border-right: none;border-bottom: none;padding: 2px!important;width: 25%;" colspan="1"><b>Total Net</b></td>
                  <td class="text-right tdTable" style="border-bottom: none;padding: 2px!important;width: 25%;" colspan="1">{{moneyFormatter(totalNetCmdClient)}}</td>
                </tr>
                <tr style="padding: 2px">
                  <td class="tdTable" style="border: none;padding: 2px!important;width: 50%;" colspan="5"><b>Montant du règl: </b> {{moneyFormatter(montantRgleForRecu)}} {{ devise }} </td>
                  <td class="text-right tdTable" style="border-right: none;border-bottom: none;padding: 2px!important;width: 25%;" colspan="1"><b>Total réglé</b></td>
                  <td class="text-right tdTable" style="border-bottom: none;padding: 2px!important;width: 25%;" colspan="1">{{moneyFormatter(montantRgleForRecu + sommeDesReglesAvantIndex)}}</td>
                </tr>
                <tr style="padding: 2px">
                  <td class="tdTable" style="border: none;padding: 2px!important;width: 50%;" colspan="5">Soit: {{ FormatNumberToLetter(montantRgleForRecu) }}</td>
                  <td class="text-right tdTable" style="border-right: none;padding: 2px!important;width: 25%;" colspan="1"><b>Reste à réglé: </b></td>
                  <td class="text-right tdTable" style="padding: 2px!important;width: 25%;" colspan="1">{{ moneyFormatter(totalNetCmdClient - (montantRgleForRecu + sommeDesReglesAvantIndex)) }}</td>
                </tr>
              </tbody>
          </table>
          <div class="vx-row">
            <div class="vx-col w-full text-center mt-5">
              Merci pour votre visite, a bientôt!!
            </div>
          </div>
        </section>
      </vue-html2pdf>
    <!-- PDF Generator end -->
    <div class="vx-col w-full">
      <vx-card class="mb-2">
        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="vx-row">
              <div class="vx-col md:w-1/5">
                <vs-input class="w-full" type="date" v-model="dateCaisse" @change="gotToSelectOperationOfDateSelected()" :max="new Date(Date.now()).toISOString().split('T')[0]"></vs-input>
                <!-- <flat-pickr v-model.lazy="dateCaisse" :config="{ dateFormat: 'd F Y', maxDate: new Date(Date.now()) }" class="w-full" name="dateProformat"  /> -->
              </div>
              <div class="vx-col md:w-1/5">
                <vs-select v-if="principale" placeholder="Officine" autocomplete class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model.lazy="IdOfficine" @change="SelectOperationOfDateSelected()">
                  <vs-select-item  class="w-full" :key="index" :value="item._id" :text="item.NomOffic" v-for="(item,index) in officines" />
                </vs-select>
              </div>
              <div class="vx-col md:w-3/5">
                <vue-simple-suggest
                  class="w-full"
                  pattern="\w+"
                  v-model="model"
                  :list="getClientList"
                  :max-suggestions="200"
                  :min-length="3"
                  :debounce="200"
                  :filter-by-query="false"
                  :prevent-submit="true"
                  :controls="{
                    selectionUp: [38, 33],
                    selectionDown: [40, 34],
                    select: [13, 36],
                    hideList: [27, 35]
                  }"
                  :mode="mode"
                  :nullable-select="true"
                  ref="suggestComponent"
                  placeholder="Search information..."
                  value-attribute="id"
                  display-attribute="fullname"
                  @select="onSuggestSelect">

                  <div class="g">
                    <input class="w-full" type="text" placeholder="Recherche Client: Nom prénoms">
                  </div>

                  <template slot="misc-item-above" slot-scope="{ suggestions, query }">
                    <div class="misc-item">
                      <span>Vous recherchez '{{ query }}'.</span>
                    </div>

                    <template v-if="suggestions.length > 0">
                      <div class="misc-item">
                        <span>{{ suggestions.length }} suggestions sont affichées...</span>
                      </div>
                      <hr>
                    </template>

                    <div class="misc-item" v-else-if="!loading">
                      <span>Aucun résultat</span>
                    </div>
                  </template>

                  <div slot="suggestion-item" slot-scope="scope" :title="`${scope.suggestion.Nom} ${scope.suggestion.Prenoms}`">
                    <div class="text">
                      <span v-html="boldenSuggestion(scope)"></span>
                    </div>
                  </div>

                  <div class="misc-item" slot="misc-item-below" slot-scope="{}" v-if="loading">
                    <span>Chargement...</span>
                  </div>
                </vue-simple-suggest>
              </div>

            </div>
          </div>
          <!-- <div class="vx-col md:w-1/2 text-right">
            {{timestamp | moment("HH:mm:ss") }} (UTC)
          </div> -->
        </div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <vs-alert :active="!CaisseIsInit" class="mt-5" color="danger" icon-pack="feather" icon="icon-alert-triangle">
              Attention veuillez initialiser la caisse du jour avant la clôture !
            </vs-alert>
            <vs-alert :active="jourFermer || jourSelectedIsFerier" class="mt-5" color="danger" icon-pack="feather" icon="icon-alert-triangle">
              Impossible d'initialiser la caisse a la date du {{this.dateCaisse | moment("calendar", "July 10 2011")}}: jour non ouvrable
            </vs-alert>
            <vs-alert :active="!CaisseIsInit" class="mt-5" color="warning" icon-pack="feather" icon="icon-alert-triangle">
              La caisse du {{this.dateCaisse | moment("calendar", "July 10 2011")}} n'est  pas initialisée. Voulez-vous l'initialisé avec la cloture de caisse du  {{getYesterdayDate | moment("calendar", "July 10 2011")}} ?
            </vs-alert>

          </div>
        </div>
        <div class="vx-row mt-2" v-if="!CaisseIsInit">
          <template v-if="!jourSelectedIsFerier">
            <div class="vx-col">
              <vs-button v-if="!jourFermer" color="primary" class="w-full" @click="initialisationCaisseAvecClotureCaisse">Initialisation avec clôture</vs-button>
            </div>
          </template>
          <!-- <div class="vx-col">
            <vs-button v-if="!jourFermer" color="danger" class="w-full" @click="showFormInitialisationCaisse">Initialisation direct</vs-button>
          </div> -->
        </div>
        <vs-divider />
        <div class="vx-row">
          <div class="vx-col md:w-1/6">
            <vs-button color="lightgray" class="w-full btnTextColor p-3" @click="popopFondDeCaisse=true">Fond de caisse</vs-button>
          </div>
          <div class="vx-col md:w-1/6">
            <vs-button color="lightgray" class="w-full btnTextColor p-3" @click="popupConsultation=true">Examen</vs-button>
          </div>
          <div class="vx-col md:w-1/6">
            <vs-button color="lightgray" class="w-full btnTextColor p-3"  @click="popopVenteComptoire=true">Comptoire</vs-button>
          </div>
          <div class="vx-col md:w-1/6">
            <vs-button color="lightgray" class="w-full btnTextColor p-3" @click="popupSelectCommande=true" >Règlement</vs-button>
          </div>
          <div class="vx-col md:w-1/6">
            <vs-button color="lightgray" class="w-full btnTextColor p-3"  @click="popoupVersement=true">Versement</vs-button>
          </div>
          <div class="vx-col md:w-1/6">
            <vs-button color="lightgray" class="w-full btnTextColor p-3"  @click="popupCloture=true">Cloture</vs-button>
          </div>
        </div>
      </vx-card>
      <vx-card class="mb-2" v-if="cmd_a_regle.length > 0" refresh-content-action @refresh="cmd_a_regle=[]">
        <div class="vx-row">
          <div class="vx-col w-full">
            <vs-table pagination max-items="10" :data="cmd_a_regle">
              <template slot="header">
                <template v-if="clientId">
                  Client: {{clientsData.find((c) => c._id === clientId).Nom}} {{clientsData.find((c) => c._id === clientId).Prenoms}}
                </template>
              </template>
              <template slot="thead">
                <vs-th sort-key="_id">
                  Ref
                </vs-th>
                <vs-th sort-key="Libelle">Libelle</vs-th>
                <vs-th sort-key="pu">P.U</vs-th>
                <vs-th sort-key="qte">Qte</vs-th>
                <vs-th sort-key="pt">P.T</vs-th>
              </template>

              <template slot-scope="{data}">
                <tbody>
                  <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                    <vs-td>
                      {{tr.Ref}}
                    </vs-td>
                    <vs-td>
                      {{tr.Libelle}}
                    </vs-td>
                    <vs-td>
                      {{moneyFormatter(+tr.PU)}}
                    </vs-td>
                    <vs-td>
                      {{tr.Qte}}
                    </vs-td>
                    <vs-td>
                      {{moneyFormatter(+tr.PT)}}
                    </vs-td>

                  </vs-tr>
                </tbody>
              </template>
            </vs-table>
          </div>
        </div>
      </vx-card>
      <vx-card class="mb-2" v-if="venteComptoires.length > 0">
        <div class="vx-row">
          <div class="vx-col w-full">
            <vs-table pagination max-items="10" :data="venteComptoires">
              <template slot="header">
                <template v-if="clientId">
                  Client: {{clientsData.find((c) => c._id === clientId).Nom}} {{clientsData.find((c) => c._id === clientId).Prenoms}}
                </template>
                <template v-else>
                  Client: divers
                </template>
              </template>
              <template slot="thead">
                <vs-th sort-key="Libelle">Libelle</vs-th>
                <vs-th sort-key="pu">P.U HT</vs-th>
                <vs-th sort-key="qte">Qte</vs-th>
                <vs-th sort-key="pt">P.T HT</vs-th>
              </template>

              <template slot-scope="{data}">
                <tbody>
                  <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                    <vs-td>
                      {{tr.Etiquette}} | {{getMarqueName(tr.IdMarque)}} {{tr.Modele}} {{tr.Couleur}}
                    </vs-td>
                    <vs-td :data="tr.PrixVenteHT">
                      {{moneyFormatter(tr.PrixVenteHT)}}
                      <template slot="edit">
                        <vs-input class="w-full mt-4" type="number" :min="tr.PrixPlanche" label="Prix Vente HT"  name="PrixVenteHT" v-model="tr.PrixVenteHT" />
                      </template>
                    </vs-td>
                    <vs-td :data="tr.stock">
                      {{tr.stock}}
                      <template slot="edit">
                        <vs-input class="w-full mt-4" type="number" :max="tr.stock" label="Qté"  name="stock" v-model="tr.stock" />
                      </template>
                    </vs-td>
                    <vs-td>
                      {{moneyFormatter((tr.PrixVenteHT * tr.stock))}}
                    </vs-td>

                  </vs-tr>
                </tbody>
              </template>
            </vs-table>
          </div>
        </div>
      </vx-card>

      <vx-card class="mb-2">
        <div class="vx-row">
          <div class="vx-col w-full">
            <h5>Opération du: {{dateCaisse | moment("calendar", "July 10 2011") }}</h5>
          </div>
        </div>
        <vs-divider />
        <div class="vx-row">
          <div class="vx-col md:w-1/2">
          <div class="vx-row">
            <div class="vx-col w-full">
              <vx-card :noShadow="true" :cardBorder="true">
                <vs-table noDataText="Aucune opérations" pagination max-items="20" :data="operationsToDayData">
                  <template slot="thead">
                    <vs-th sort-key="Date">
                      Date
                    </vs-th>
                    <vs-th sort-key="modepaiement">Moyen</vs-th>
                    <vs-th sort-key="pu">Caissier</vs-th>
                    <vs-th sort-key="typeOperation">Opération</vs-th>
                    <vs-th sort-key="montant">Montant</vs-th>
                  </template>

                  <template slot-scope="{data}">
                    <tbody>
                      <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                        <vs-td>
                          {{tr.dateOperation  | moment("calendar", "July 10 2011")}}
                        </vs-td>
                        <vs-td>
                          {{tr.modepaiement}}
                        </vs-td>
                        <vs-td>
                          {{getUserById(tr.IdUser) ? getUserById(tr.IdUser).nom : ''}} {{getUserById(tr.IdUser) ? getUserById(tr.IdUser).prenoms : ''}}
                        </vs-td>
                        <vs-td>
                          {{tr.typeOperation}}
                        </vs-td>
                        <vs-td>
                          {{tr.montant ? moneyFormatter(tr.montant) : 0}}
                        </vs-td>

                      </vs-tr>
                    </tbody>
                  </template>
                </vs-table>
              </vx-card>
            </div>
            <div class="vx-col w-full mt-2">
              <vx-card :noShadow="true" :cardBorder="true">
                <div class="vx-row">
                  <div class="vx-col md:w-1/5 bg-primary">
                    <h5 style="color: white">Chèq</h5>
                  </div>
                  <div class="vx-col md:w-2/5 bg-black">
                    <h5 class="w-full" style="color: white">Chèq reçu: {{moneyFormatter(MontantCheqRecu)}}</h5>
                  </div>
                  <div class="vx-col md:w-2/5 bg-black">
                    <h5 class="w-full" style="color: white">Chèq emis: {{moneyFormatter(MontantCheqEmis)}} </h5>
                  </div>
                </div>
                <div class="vx-row mt-2">
                  <div class="vx-col md:w-1/5  bg-primary">
                    <h5 style="color: white">Fd de caisse</h5>
                  </div>
                  <div class="vx-col md:w-4/5  bg-black">
                    <h5 class="w-full" style="color: white">{{moneyFormatter(getFondDeCaisse)}} </h5>
                  </div>
                </div>
                <div class="vx-row mt-2">
                  <div class="vx-col md:w-1/5  bg-primary">
                    <h5 style="color: white">Mouv</h5>
                  </div>
                  <div class="vx-col md:w-2/5  bg-black">
                    <h5 class="w-full" style="color: white">Décaissé: {{moneyFormatter(MontantDecaisse)}} </h5>
                  </div>
                  <div class="vx-col md:w-2/5  bg-black">
                    <h5 class="w-full" style="color: white">Encaissé: {{moneyFormatter(EncaisseSelectDate)}} </h5>
                  </div>
                </div>
                <div class="vx-row mt-2">
                  <div class="vx-col md:w-1/5 bg-primary">
                    <h5 style="color: white">En caisse</h5>
                  </div>
                  <div class="vx-col md:w-4/5 bg-black">
                    <h5 class="w-full" style="color: white">{{moneyFormatter(totalEncaisse)}}</h5>
                  </div>
                </div>
              </vx-card>
            </div>
          </div>
          </div>
          <div class="vx-col md:w-1/2">
            <vx-card :noShadow="true" :cardBorder="true">
              <div class="vx-row">
                <div class="vx-col md:w-1/2">
                  <div class="vx-row">
                    <div class="vx-col md:w-1/2 text-right">
                      Total:
                    </div>
                    <div class="vx-col md:w-1/2 bg-black">
                      <span style="color: white">{{moneyFormatter(totalCmdClient)}}</span>
                    </div>
                    <div class="vx-col md:w-1/2 text-right mt-4">
                      Remise:
                    </div>
                    <div class="vx-col md:w-1/2 bg-black mt-4">
                      <span style="color: white">{{moneyFormatter(remiseCmdClient)}}</span>
                    </div>
                    <div class="vx-col md:w-1/2 text-right mt-4">
                      Total Net:
                    </div>
                    <div class="vx-col md:w-1/2 bg-black mt-4">
                      <span style="color: white">{{moneyFormatter(totalNetCmdClient)}}</span>
                    </div>
                    <div class="vx-col md:w-1/2 text-right mt-4">
                      Part assurance:
                    </div>
                    <div class="vx-col md:w-1/2 bg-black mt-4">
                      <span style="color: white">{{moneyFormatter(partAssu)}}</span>
                    </div>
                    <div class="vx-col md:w-1/2 text-right mt-4">
                      Part client:
                    </div>
                    <div class="vx-col md:w-1/2 bg-black mt-4 mb-2">
                      <span style="color: white">{{moneyFormatter(partClient)}}</span>
                    </div>
                  </div>
                  <hr>
                  <div class="vx-row mt-2">
                    <div class="vx-col md:w-1/2 text-right">
                      Net à payer:
                    </div>
                    <div class="vx-col md:w-1/2 bg-black">
                      <span style="color: white">{{moneyFormatter(totalNetCmdClient)}}</span>
                    </div>
                    <div class="vx-col md:w-1/2 text-right mt-4">
                      Encaissé:
                    </div>
                    <div class="vx-col md:w-1/2 bg-black mt-4">
                      <span style="color: white">{{moneyFormatter(encaisse)}}</span>
                    </div>
                    <div class="vx-col md:w-1/2 text-right mt-4">
                      Avoir:
                    </div>
                    <div class="vx-col md:w-1/2 bg-black mt-4">
                      <span style="color: white">{{moneyFormatter(0)}}</span>
                    </div>
                    <div class="vx-col md:w-1/2 text-right mt-4">
                      Solde:
                    </div>
                    <div class="vx-col md:w-1/2 bg-primary mt-4">
                      <span style="color: white">{{moneyFormatter(totalNetCmdClient - encaisse)}}</span>
                    </div>
                  </div>
                </div>
                <div class="vx-col md:w-1/2">
                  <div class="vx-row ml-2">
                    <div class="vx-col w-full bg-darkgrey text-center">
                      <span style="color: white">Règlement</span>
                    </div>
                    <template v-if="CaisseIsInit">
                      <div v-if="(!billetageOnSelectDay)" class="vx-col w-full mt-4">
                        <vs-button color="primary" class="w-full" @click="reglementClient">Client</vs-button>
                      </div>
                    </template>
                    <template v-if="CaisseIsInit">
                      <div v-if="(!billetageOnSelectDay)" class="vx-col w-full mt-4">
                        <vs-button v-if="venteComptoires.length > 0" color="primary" class="w-full" @click="popopReglcmdComptoire=true">Règl comptoire</vs-button>
                      </div>
                    </template>
                    <div v-if="cmd_a_regle.length > 0" class="vx-col w-full mt-4">
                      <vs-button color="primary" class="w-full" @click="showSideBarRecuOperation">Historique de règl.</vs-button>
                    </div>
                    <div class="vx-col w-full mt-4">
                      <div class="vx-row">
                        <div class="vx-col md:w-2/5">
                          10 000 X {{billetageOnSelectDay ? ((billetageOnSelectDay.MontDixMil ? +billetageOnSelectDay.MontDixMil : 0)/10000) : ''}}
                        </div>
                        <div class="vx-col md:w-1/5">
                          =
                        </div>
                        <div class="vx-col md:w-2/5">
                          {{billetageOnSelectDay ? (billetageOnSelectDay.MontDixMil ? moneyFormatter(+billetageOnSelectDay.MontDixMil) : 0) : ''}}
                        </div>
                      </div>
                      <div class="vx-row">
                        <div class="vx-col md:w-2/5">
                          5 000 X {{billetageOnSelectDay ? ((billetageOnSelectDay.MontCqMil ? +billetageOnSelectDay.MontCqMil : 0)/5000) : ''}}
                        </div>
                        <div class="vx-col md:w-1/5">
                          =
                        </div>
                        <div class="vx-col md:w-2/5">
                          {{billetageOnSelectDay ? (billetageOnSelectDay.MontCqMil ? moneyFormatter(+billetageOnSelectDay.MontCqMil) : 0) : ''}}
                        </div>
                      </div>
                      <div class="vx-row">
                        <div class="vx-col md:w-2/5">
                          2 000 X {{billetageOnSelectDay ? ((billetageOnSelectDay.MontDeuxMil ? +billetageOnSelectDay.MontDeuxMil : 0)/2000) : ''}}
                        </div>
                        <div class="vx-col md:w-1/5">
                          =
                        </div>
                        <div class="vx-col md:w-2/5">
                          {{billetageOnSelectDay ? (billetageOnSelectDay.MontDeuxMil ? moneyFormatter(+billetageOnSelectDay.MontDeuxMil) : 0) : ''}}
                        </div>
                      </div>
                      <div class="vx-row">
                        <div class="vx-col md:w-2/5">
                          1 000 X {{billetageOnSelectDay ? ((billetageOnSelectDay.MontMil ? +billetageOnSelectDay.MontMil : 0)/1000) : ''}}
                        </div>
                        <div class="vx-col md:w-1/5">
                          =
                        </div>
                        <div class="vx-col md:w-2/5">
                          {{billetageOnSelectDay ? (billetageOnSelectDay.MontMil ? moneyFormatter(+billetageOnSelectDay.MontMil) : 0) : ''}}
                        </div>
                      </div>
                      <div class="vx-row">
                        <div class="vx-col md:w-2/5">
                          500 X {{billetageOnSelectDay ? ((billetageOnSelectDay.MontCqCnt ? +billetageOnSelectDay.MontCqCnt : 0)/500) : ''}}
                        </div>
                        <div class="vx-col md:w-1/5">
                          =
                        </div>
                        <div class="vx-col md:w-2/5">
                          {{billetageOnSelectDay ? (billetageOnSelectDay.MontCqCnt ? (+billetageOnSelectDay.MontCqCnt) : 0) : ''}}
                        </div>
                      </div>
                      <div class="vx-row">
                        <div class="vx-col md:w-2/5">
                          250 X {{billetageOnSelectDay ? ((billetageOnSelectDay.MontDeuxCntCqt ? +billetageOnSelectDay.MontDeuxCntCqt : 0)/250) : ''}}
                        </div>
                        <div class="vx-col md:w-1/5">
                          =
                        </div>
                        <div class="vx-col md:w-2/5">
                          {{billetageOnSelectDay ? (billetageOnSelectDay.MontDeuxCntCqt ? moneyFormatter(+billetageOnSelectDay.MontDeuxCntCqt) : 0) : ''}}
                        </div>
                      </div>
                      <div class="vx-row">
                        <div class="vx-col md:w-2/5">
                          200 X {{billetageOnSelectDay ? ((billetageOnSelectDay.MontDeuxCnt ? +billetageOnSelectDay.MontDeuxCnt : 0)/200) : ''}}
                        </div>
                        <div class="vx-col md:w-1/5">
                          =
                        </div>
                        <div class="vx-col md:w-2/5">
                          {{billetageOnSelectDay ? (billetageOnSelectDay.MontDeuxCnt ? moneyFormatter(+billetageOnSelectDay.MontDeuxCnt) : 0) : ''}}
                        </div>
                      </div>
                      <div class="vx-row">
                        <div class="vx-col md:w-2/5">
                          100 X {{billetageOnSelectDay ? ((billetageOnSelectDay.MontCnt ? +billetageOnSelectDay.MontCnt : 0)/100) : ''}}
                        </div>
                        <div class="vx-col md:w-1/5">
                          =
                        </div>
                        <div class="vx-col md:w-2/5">
                          {{billetageOnSelectDay ? (billetageOnSelectDay.MontCnt ? moneyFormatter(+billetageOnSelectDay.MontCnt) : 0) : ''}}
                        </div>
                      </div>
                      <div class="vx-row">
                        <div class="vx-col md:w-2/5">
                          50 X {{billetageOnSelectDay ? ((billetageOnSelectDay.MontCqt ? +billetageOnSelectDay.MontCqt : 0)/50) : ''}}
                        </div>
                        <div class="vx-col md:w-1/5">
                          =
                        </div>
                        <div class="vx-col md:w-2/5">
                          {{billetageOnSelectDay ? (billetageOnSelectDay.MontCqt ? moneyFormatter(+billetageOnSelectDay.MontCqt) : 0) : ''}}
                        </div>
                      </div>
                      <div class="vx-row">
                        <div class="vx-col md:w-2/5">
                          25 X {{billetageOnSelectDay ? ((billetageOnSelectDay.MontVingtCinq ? +billetageOnSelectDay.MontVingtCinq : 0)/25) : ''}}
                        </div>
                        <div class="vx-col md:w-1/5">
                          =
                        </div>
                        <div class="vx-col md:w-2/5">
                          {{billetageOnSelectDay ? (billetageOnSelectDay.MontVingtCinq ? moneyFormatter(+billetageOnSelectDay.MontVingtCinq) : 0) : ''}}
                        </div>
                      </div>
                      <div class="vx-row">
                        <div class="vx-col md:w-2/5">
                          10 X {{billetageOnSelectDay ? ((billetageOnSelectDay.MontDix ? +billetageOnSelectDay.MontDix : 0)/10) : ''}}
                        </div>
                        <div class="vx-col md:w-1/5">
                          =
                        </div>
                        <div class="vx-col md:w-2/5">
                          {{billetageOnSelectDay ? (billetageOnSelectDay.MontDix ? moneyFormatter(+billetageOnSelectDay.MontDix) : 0) : ''}}
                        </div>
                      </div>
                      <div class="vx-row">
                        <div class="vx-col md:w-2/5">
                          5 X {{billetageOnSelectDay ? ((billetageOnSelectDay.MontCinq ? +billetageOnSelectDay.MontCinq : 0)/5) : ''}}
                        </div>
                        <div class="vx-col md:w-1/5">
                          =
                        </div>
                        <div class="vx-col md:w-2/5">
                          {{billetageOnSelectDay ? (billetageOnSelectDay.MontCinq ? moneyFormatter(+billetageOnSelectDay.MontCinq) : 0) : ''}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </vx-card>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Datepicker from 'vuejs-datepicker'
import 'flatpickr/dist/flatpickr.css'
import VueHtml2pdf from 'vue-html2pdf'
import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css'
import axios from '@/axios.js'
import utils from '@/conversion-en-lettre/index.js'
export default {
  name: 'Caisse',
  components: {
    vSelect,
    flatPickr,
    Datepicker,
    VueHtml2pdf,
    VueSimpleSuggest
  },
  data () {
    return {
      model: null,
      mode: 'input',
      loading: false,
      chosen: '',
      selected: null,
      clientName: '',
      showFullSearch: false,

      inputFocused: false,
      insideSuggestions: false,

      isSidebarActiveRecuOperation: false,
      devise: 'FCFA',

      IdOfficine: null,
      clientId: null,
      selectClient: null,
      cmd_a_regle: [],
      commandes_client_all: null,
      RefCmmd: '',
      commandeIndex: null,
      popupSelectCommande: false,
      popupSelectRegleClient: false,
      changeCaisseDate: false,
      dateCaisse: new Date(Date.now()).toISOString().split('T')[0],
      dateCaisseOld: null,
      dateCaisseNew: null,
      format: 'yyyy-MM-dd',

      /* commande client */
      totalCmdClient: 0,
      remiseCmdClient: 0,
      totalNetCmdClient: 0,
      partAssu: 0,
      partClient: 0,
      encaisse: 0,

      /* reglèment */
      IdUser: null,
      DateRegle: Date.now(),
      modeRegl: 'Espèce',
      NumCheque: '',
      Montant: 0,
      PartClient: 0,
      PartAssu: 0,
      NumPieceCaisse: '',
      reglement_clients: null,
      remarque: '',

      MontantDu: 0,
      MontantRecu: 0,
      Monnaie: 0,
      moyen_paiement:
      [
        {libelle: 'Espèce', value: 'Espèce'},
        {libelle: 'Chèque', value: 'Chèque'},
        {libelle: 'Virement bancaire', value: 'Virement bancaire'},
        {libelle: 'Transfert', value: 'Transfert'}
      ],
      alert: false,
      alertMontantrecu: false,
      alertMontantSup: false,
      montantSaisie: 0,

      timestamp: Date(),

      /* Impression de recu */
      montantRgleForRecu: 0,
      sommeDesReglesAvantIndex: 0,

      /* Fond de caisse */
      popopFondDeCaisse: false,
      MontFond: 0,
      DateDebutExercice: null,
      DateFinExercice: null,
      MontObservation: '',

      /* Consultation  */
      popupConsultation: false,
      Patient: '',
      MontantConsultation: '',
      ObservationConsultation: '',

      /* Vente au comptoire */
      popopVenteComptoire: false,
      popopReglcmdComptoire: false,
      venteComptoires: [],
      RemiseComComptoire: null,
      TotalFactNetComptoire: null,
      PartClientComptoire: null,
      PartAssuComptoire: null,
      NumChequeComptoire: '',

      /* Dépense */
      popupDepense: false,
      dateCaisseDepense: (new Date()).setTime(0),
      changeCaisseDepenseDate: false,
      DateOp: '',
      HeurOp: '',
      Beneficiaire: '',
      Motif: '',
      MoyPaiement: '',
      MontantDepense: '',
      OrigineRecette: '',
      CheminPreuv: '',
      NumChequeDepense: '',
      MotDePasse: '',
      Mouvement: '',

      /* Versement */
      verCaisseDepense: false,
      popoupVersement: false,
      DateDepot: null,
      Banque: '',
      MontantDeposer: 0,
      Coursier: '',
      ObservationVersement: '',

      /* Cloture */
      popupCloture: false,
      popupformInitialisationCaisse: false,
      //jourFermer: false,
      montantInitialisationCaisse: null,

      Observation: '',
      TotalEnCaisse: 0,
      nbreDixMil: 0,
      nbreCqMil: 0,
      nbreDeuxMil: 0,
      nbreMil: 0,
      nbreCqCnt: 0,
      nbreDeuxCntCq: 0,
      nbreDeuxCnt: 0,
      nbreCnt: 0,
      nbreCqt: 0,
      nbreVingtCinq: 0,
      nbreDix: 0,
      nbreCinq: 0,
      MontDixMil: 0,
      MontCqMil: 0,
      MontDeuxMil: 0,
      MontMil: 0,
      MontCqCnt: 0,
      MontDeuxCntCq: 0,
      MontDeuxCnt: 0,
      MontCnt: 0,
      MontCqt: 0,
      MontVingtCinq: 0,
      MontDix: 0,
      MontCinq: 0,
      MontCheqRecu: 0,
      MontCeqEmis: 0
    }
  },
  watch: {},
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    operationsToDayData () {
      return this.$store.state.operation.operationsToday
    },
    /* commande comptoire */

    /* end commande comptoire */
    billetageOnSelectDay () {
      return this.$store.state.billetage.billetage
    },
    EncaisseSelectDate () {
      return this.$store.getters['operation/EncaisseSelectDate']
    },
    MontantDecaisse () {
      return this.$store.getters['operation/MontantDecaisse']
    },
    MontantCheqRecu () {
      return this.$store.getters['operation/MontantCheqRecu']
    },
    MontantCheqEmis () {
      return this.$store.getters['operation/MontantCheqEmis']
    },
    disableBtnCmdecmptoire () {
      return this.venteComptoires.length === 0
    },
    clientsData () {
      return this.$store.state.client.clients
    },
    fondDeCaisseData () {
      return this.$store.state.fonddecaisse.fonddecaisses
    },
    BanqueData () {
      return this.$store.state.banque.banques
    },
    officines () {
      return this.$store.state.officine.officines
    },
    stocks () {
      return this.$store.state.stock.articles
    },
    versementsData () {
      return this.$store.state.versementBanque.versements
    },
    getArticleWhereQtyIsSupThanZero () {
      return this.$store.getters['stock/getArticleWhereQtyIsSupThanZeroByOfficineId'](this.IdOfficine)
    },
    ferierData () {
      return this.$store.state.configs.feries
    },
    totalDixMil () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.MontDixMil = +this.nbreDixMil * 10000
      return this.MontDixMil
    },
    totalCqMil () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.MontCqMil = +this.nbreCqMil * 5000
      return this.MontCqMil
    },
    totalDeuxMil () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.MontDeuxMil = +this.nbreDeuxMil * 2000
      return this.MontDeuxMil
    },
    totalMil () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.MontMil = +this.nbreMil * 1000
      return this.MontMil
    },
    totalCqCnt () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.MontCqCnt = +this.nbreCqCnt * 500
      return this.MontCqCnt
    },
    totalDeuxCntCqt () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.MontDeuxCntCq = +this.nbreDeuxCntCq * 250
      return this.MontDeuxCntCq
    },
    totalDeuxCnt () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.MontDeuxCnt = +this.nbreDeuxCnt * 200
      return this.MontDeuxCnt
    },
    totalCnt () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.MontCnt = +this.nbreCnt * 100
      return this.MontCnt
    },
    totalCqt () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.MontCqt = +this.nbreCqt * 50
      return this.MontCqt
    },
    totalVingtCinq () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.MontVingtCinq = +this.nbreVingtCinq * 25
      return this.MontVingtCinq
    },
    totalDix () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.MontDix = +this.nbreDix * 10
      return this.MontDix
    },
    totalCinq () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.MontCinq = +this.nbreCinq * 5
      return this.MontCinq
    },
    totalCaisse () {
      const propertiesToSum = [
        'MontDixMil', 'MontCqMil', 'MontDeuxMil', 'MontMil',
        'MontCqCnt', 'MontDeuxCntCq', 'MontDeuxCnt', 'MontCnt',
        'MontCqt', 'MontVingtCinq', 'MontDix', 'MontCinq'
      ]

      let total = 0

      for (const prop of propertiesToSum) {
        total += this[prop] || 0
      }

      return total
    },
    totalEncaisse () {
      const fondDeCaisse = this.$store.getters['operation/FondCaisse']
      const encaisseSelectDate = this.EncaisseSelectDate || 0
      const montantDecaisse = this.MontantDecaisse || 0
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.TotalEnCaisse = (encaisseSelectDate + fondDeCaisse) - montantDecaisse
      return this.TotalEnCaisse
    },
    ecartCaisse () {
      return 0
    },
    principale () {
      return this.$store.getters['officine/officineIsPrincipale'](JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).IdOfficine : null)
    },

    CaisseIsInit () {
      return this.$store.getters['operation/CaisseIsInit']
    },
    getFondDeCaisse () {
      return this.$store.getters['operation/getFondDeCaisse']
    },
    jourSelectedIsFerier () {
      return this.$store.getters['configs/dayIsFerie'](this.dateCaisse)
    },
    jourFermer () {
      const idOfficine = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).IdOfficine : null
      const officineJoursOuvrable = this.$store.getters['officine/getOfficineJoursOuvrable'](idOfficine)
      const currentDate = new Date(this.dateCaisse)
      const currentDay = currentDate.getDay()

      return !officineJoursOuvrable.includes(currentDay)
    },
    getYesterdayDate () {
      const idOfficine = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).IdOfficine : null
      const officineJoursOuvrable = this.$store.getters['officine/getOfficineJoursOuvrable'](idOfficine)

      const currentDate = new Date(this.dateCaisse)
      //const jour = new Date(currentDate).getDay()
      const yesterday = new Date(currentDate)
      let iterations = 0
      // for(; ;) crée un boucle infinie
      while (iterations < 1000) { // Limite arbitraire pour éviter une boucle infinie
        yesterday.setDate(yesterday.getDate() - 1)
        const currentDay = yesterday.getDay()

        // Vérifiez si le jour est un jour ouvrable et non férié
        if (officineJoursOuvrable.includes(currentDay) && !this.$store.getters['configs/dayIsFerie'](yesterday)) {
          break
        }

        iterations++
      }

      return yesterday.toISOString().split('T')[0]
    }
  },
  methods: {
    FormatNumberToLetter (nombre) {
      return utils.NumberToLetter(nombre ? nombre : 0)
    },
    getUserById (id) {
      return this.$store.getters['userManagement/getUserById'](id)
    },
    getClientList (inputValue) {
      return new Promise((resolve, reject) => {
        /* const url = `https://en.wikipedia.org/w/api.php?origin=*&action=opensearch&namespace=*&search=${inputValue}&limit=10&namespace=0&format=json` */
        axios.get(`/api/client-full-search/${this.IdOfficine}/${inputValue}`)
          .then((response) => {
            /* const fields = ['Nom', 'Prenoms'] */
            const result = response.data
            response.data.forEach((part, index) => {
              //console.log('part', part)
              response.data[index].fullname = `${part.Nom} ${part.Prenoms}`
            })
            //console.log('result', result)
            resolve(result)
            /* resolve(response) */
          })
          .catch((err) => {
            reject(err)
          })

      })
    },
    onSuggestSelect (suggest) {
      this.selected = suggest
      this.$store.commit('client/ADD_SEARCH_CLIENT', suggest)
      this.getCommandeOnSelectClient(suggest._id)

    },
    boldenSuggestion (scope) {
      if (!scope) return scope
      const { suggestion, query } = scope
      const result = this.$refs.suggestComponent.displayProperty(suggestion)
      if (!query) return result
      const texts = query.split(/[\s-_/\\|.]/gm).filter(t => !!t) || ['']
      return result.replace(new RegExp(`(.*?)(${  texts.join('|')  })(.*?)`, 'gi'), '$1<b>$2</b>$3')
    },
    showFormInitialisationCaisse () {
      this.popupformInitialisationCaisse = true
    },
    initialisationCaisse (tr) {
      const payload = {
        IdOfficine: this.IdOfficine,
        IdUser: this.IdUser,
        DateCaisse: this.dateCaisse,
        idFond: tr._id
      }

      this.$vs.loading({
        type: 'sound'
      })

      this.$store.dispatch('operation/initialisationCaisse', payload)
        .then(() => {
          this.getOperationToDay()
          this.popupformInitialisationCaisse = false
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'error',
            text: `${err.message}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })


    },
    initialisationCaisseAvecClotureCaisse () {
      const payload = {
        IdOfficine: this.IdOfficine,
        IdUser: this.IdUser,
        DateClotureCaisse: this.getYesterdayDate,
        DateCaisse: this.dateCaisse
      }

      this.$vs.loading({
        type: 'sound'
      })

      this.$store.dispatch('operation/initialisationCaisseAvecClotureCaisse', payload)
        .then((resp) => {
          if (resp.data.message === 'cloture non effectuer') {
            this.$vs.loading.close()
            this.$vs.dialog({
              type: 'alert',
              color: 'danger',
              title: 'Error',
              text: `Effectuer la cloture de caisse du: ${this.getYesterdayDate}`,
              buttonAccept: 'true'
            })
          } else if (resp.data.message === 'Caisse déjà initialiser') {
            this.$vs.loading.close()
            this.$vs.dialog({
              type: 'alert',
              color: 'danger',
              title: 'Error',
              text: `Caisse du ${this.getYesterdayDate} déjà initialiser`,
              buttonAccept: 'true'
            })
          } else {
            this.getOperationToDay()
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'success',
              text: 'success',
              iconPack: 'feather',
              icon: 'icon-check',
              color: 'primary'
            })

          }
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    gotToSelectOperationOfDateSelected () {
      if (this.dateCaisse) {
        this.SelectOperationOfDateSelected()
      }
    },
    escPressed () {
      this.$emit('closeSearchbar')
      this.searchQuery = ''
    },
    updateInputFocus (val = true) {
      if (val) {
        if (this.searchQuery === '') this.inputInit()
        setTimeout(() => {
          this.inputFocused = true
        }, 100)
      } else {
        if (this.insideSuggestions) return
        setTimeout(() => {
          this.inputFocused = false
        }, 100)
        this.escPressed()
      }
    },
    showSideBarRecuOperation () {
      this.isSidebarActiveRecuOperation = true
    },
    async generateReport (index) {
      try {
        const regl_a_imprimer = this.reglement_clients[index]
        this.montantRgleForRecu = regl_a_imprimer.Montant
        this.DateRegle = regl_a_imprimer.DateRegle

        // Calcul de la somme des règlements avant l'index
        this.sommeDesReglesAvantIndex = this.reglement_clients
          .slice(0, index)
          .reduce((total, reglement) => total + parseFloat(reglement.Montant), 0)

        // this.$vs.loading({ type: 'sound' })

        // Générer le rapport PDF ici
        await this.generatePdfAsync()

        // console.log('end of pdf generation')
        this.$vs.loading.close()
      } catch (error) {
        // this.$vs.loading.close()
        this.handleError(error)
      }
    },
    async generatePdfAsync () {
      return new Promise((resolve, reject) => {
        // Générer le rapport PDF ici (peut-être utiliser une bibliothèque externe)
        this.$refs.html2PdfRecu.generatePdf(() => {
          this.$vs.loading.close()
          // Résolution de la promesse pour indiquer que la génération est terminée
          resolve()
        }, error => {
          this.$vs.loading.close()
          // En cas d'erreur, rejet de la promesse avec l'erreur
          reject(error)
        })
      })
    },
    makeReglementClient () {
      const payload = {
        IdOfficine: this.IdOfficine,
        IdUser: this.IdUser,
        DateRegle: new Date(new Date(this.dateCaisse)).toISOString().split('T')[0],
        HeureOperation: new Date(new Date(this.dateCaisse)),
        modeRegl: this.modeRegl,
        NumCheque: this.NumCheque,
        Montant: this.Montant,
        PartClient: true,
        PartAssu: false,
        NumPieceCaisse: this.NumPieceCaisse,
        clientId: this.selectClient._id,
        part: 'client',
        RefCmmd: this.RefCmmd,
        remarque: this.remarque
      }

      this.$vs.loading({
        type: 'sound'
      })

      // Promise.all([
      //   //this.generatePdfAsync()
      // ])
      this.$store.dispatch('client/reglementCommandeClient', payload)
        .then((response) => {
          this.commandes_client_all = response.data.client ? response.data.client.commandes : []
          this.selectClient = response.data.client
          const cmd = response.data.client.commandes.find((c) => c.RefCmmd === this.RefCmmd)
          const index = cmd.reglements ? cmd.reglements.length - 1 : 0
          const regl_a_imprimer = cmd.reglements[index]
          this.montantRgleForRecu = regl_a_imprimer.Montant
          this.DateRegle = regl_a_imprimer.DateRegle
          this.timestamp = Date(regl_a_imprimer.HeureRegle)

          // Calcul de la somme des règlements avant l'index
          if (cmd.reglements) {
            this.sommeDesReglesAvantIndex = cmd.reglements
              .slice(0, index)
              .reduce((total, reglement) => total + parseFloat(reglement.Montant), 0)
          }
          this.Montant = 0
          this.NumCheque = ''
          this.NumPieceCaisse = ''

          this.ShowCommandeDetailForRegl(this.RefCmmd)
          this.getOperationToDay()
          console.log(cmd.reglements)

          this.encaisse = this.encaisse + this.Montant
          this.generatePdfAsync()
          this.popupSelectRegleClient = false
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
        })
        .catch(err => {
          this.handleError(err)
        })
    },

    onChangeMontantRecu () {
      if (this.alertMontantrecu === true) this.alertMontantrecu = false
    },
    onReglementSupMontantDu (montant) {

      if (this.MontantRecu !== 0) {
        this.montantSaisie = montant
        if (this.alert === true) this.alert = false
        if (montant > this.MontantDu) {
          if (this.alertMontantrecu === true) this.alertMontantrecu = false
          this.alert = true
          this.Montant = 0
        }
        this.Monnaie = this.MontantRecu - this.Montant
      } else {
        this.alertMontantrecu = true
        this.Montant = 0
      }
    },
    reglementClient () {
      if (this.cmd_a_regle.length === 0 || this.partClient <= 0) {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: 'Alert',
          text: 'Impossible d\'effectuer un règlement client sans commande chargée ou sans montant à régler',
          buttonAccept: 'true'
        })
        return
      }

      if (this.reglement_clients.length > 0) {
        const parclientRegle = this.reglement_clients.filter((p) => p.PartClient === true)

        if (parclientRegle.length > 0) {
          const montantRegle = parclientRegle.reduce((accumulator, part) => accumulator + +part.Montant, 0)

          if (montantRegle < this.partClient) {
            this.MontantDu = this.partClient - +montantRegle
            this.popupSelectRegleClient = true
          } else {
            this.$vs.dialog({
              type: 'alert',
              color: 'warning',
              title: 'Alert',
              text: 'Impossible d\'effectuer un règlement client, la part client est soldée',
              buttonAccept: 'true'
            })
          }
        } else {
          console.log('Pas encore de règlement')
          this.MontantDu = this.partClient
          this.popupSelectRegleClient = true
        }
      } else {
        this.MontantDu = this.partClient
        this.popupSelectRegleClient = true
      }
    },

    moneyFormatter (montant) {
      return montant ? montant.toLocaleString('fr-FR') : 0
    },
    getNow () {
      const today = new Date()
      const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
      const time = `${today.getHours()  }:${  today.getMinutes()  }:${  today.getSeconds()}`
      const dateTime = `${date } ${ time}`
      this.timestamp = dateTime
    },
    getCommandeOnSelectClient (id) {
      //const client = this.clientsData.find((c) => c._id === id)
      const client = this.$store.getters['client/getClientById'](id)
      this.commandes_client_all = client ? client.commandes : []
      this.selectClient = client
    },
    getMarqueName (id) {
      const singleMarque = this.$store.getters['marque/getMarqueById'](id)
      return singleMarque ? singleMarque.Libelle_marq : ''
    },
    getMarques () {
      this.$store.dispatch('marque/getMarques')
        .then(() => {
        })
        .catch(err => {
          this.handleError(err)
        })
    },
    ShowCommandeDetailForRegl (RefCmmd) {
      const cmd = this.selectClient ? this.selectClient.commandes.find((c) => c.RefCmmd === RefCmmd) : {}
      const reglementCmd = cmd ? cmd.reglements : []
      this.reglement_clients = reglementCmd

      // Encaissé commande
      this.encaisse = reglementCmd.reduce((acc, regl) => acc + parseFloat(regl.Montant), 0)

      // Commande infos
      const totalCmdClient = ['PrixMont1', 'PrixMont2', 'PrixMont3', 'PrixVerG1', 'PrixVerD1', 'PrixVerG2', 'PrixVerD2', 'PrixVerG3', 'PrixVerD3']
        .reduce((acc, prop) => acc + (+cmd[prop] || 0), 0)

      this.totalCmdClient = totalCmdClient
      this.remiseCmdClient = cmd.RemiseCom
      this.totalNetCmdClient = totalCmdClient - this.remiseCmdClient
      this.partAssu = cmd.PartAssu
      this.partClient = cmd.PartClient

      this.cmd_a_regle = []

      const loadMonture = async (articleId, cmd) => {
        try {
          const resp = await this.$store.dispatch('stock/getArticleById', articleId)
          const monture = resp.data || null
          // console.log('articleId: ', articleId)
          // console.log('monture: ', monture)
          if (monture !== null) {
            const object = {
              Ref: cmd.RefCmmd,
              Libelle: `${monture.Etiquette} | ${this.getMarqueName(monture.IdMarque)} ${monture.Modele} ${monture.Couleur}`,
              PU: cmd.PrixMont1,
              Qte: 1,
              PT: `${+cmd.PrixMont1 * 1}`
            }
            this.cmd_a_regle.push(object)
          }
        } catch (error) {
          // Gérer les erreurs liées au chargement de l'article
        }
      };

      ['IdMont1', 'IdMont2', 'IdMont3'].forEach((prop) => {
        const articleId = cmd[prop]
        //console.log('articleId: ', articleId)
        if (articleId) {
          loadMonture(articleId, cmd)
        }
      });

      ['PrixVerG1', 'PrixVerD1', 'PrixVerG2', 'PrixVerD2', 'PrixVerG3', 'PrixVerD3'].forEach((prop) => {
        if (cmd[prop]) {
          const libelleProp = prop === 'PrixVerG1' ? 'RefVerG1' : prop === 'PrixVerD1' ? 'RefVerD1' : prop === 'PrixVerD2' ? 'RefVerD2' : prop === 'PrixVerG2' ? 'RefVerG2' : prop === 'PrixVerG3' ? 'RefVerG3' : 'RefVerD3'
          const objectRef = {
            Ref: cmd.RefCmmd,
            Libelle: cmd[libelleProp],
            PU: cmd[prop],
            Qte: 1,
            PT: `${+cmd[prop] * 1}`
          }
          this.cmd_a_regle.push(objectRef)
        }
      })

      this.popupSelectCommande = false
    },

    /* Fond de caisse */
    addFondDeCaisse () {

      const payload = {
        IdUser: this.IdUser,
        IdOfficine: this.IdOfficine,
        MontFond: this.MontFond,
        DateDebutExercice: this.DateDebutExercice,
        DateFinExercice: this.DateFinExercice,
        Observation: this.MontObservation
      }

      this.$vs.loading({
        type: 'sound'
      })

      this.$store.dispatch('fonddecaisse/addFondDeCaisse', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.MontFond = 0
          this.DateDebutExercice = ''
          this.DateFinExercice = ''
          this.$vs.loading.close()
        })
        .catch(err => {
          this.handleError(err)
        })

    },
    getFondDeCaisses () {
      this.$store.dispatch('fonddecaisse/getFondDeCaisseByOfficine', this.IdOfficine)
        .then(() => {
        })
        .catch(err => {
          this.handleError(err)
        })
    },
    updateFondDeCaisse () {

    },
    deleteFondDeCaisse (id) {
      this.$vs.loading({
        type: 'sound'
      })

      this.$store.dispatch('fonddecaisse/removeFondDeCaisse', id)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.$vs.loading.close()
        })
        .catch(err => {
          this.handleError(err)
        })
    },
    /* Vente comptoire */

    VenteAuComptoireTotal () {
      /* commande infos */

      this.totalCmdClient = 0
      this.remiseCmdClient = 0

      this.venteComptoires.forEach((article) => {
        this.totalCmdClient = this.totalCmdClient + (article.PrixVenteHT * article.stock)
        console.log(typeof +article.Remise)
        console.log(+article.Remise)
        this.remiseCmdClient = +article.Remise ? (+this.RemiseCmdClient + (+article.Remise)) : 0
      })
      /* this.totalCmdClient = +cmd.PrixMont1 + +cmd.PrixMont2 + +cmd.PrixVerG1 + +cmd.PrixVerD1 + +cmd.PrixVerG2 + +cmd.PrixVerD2
      this.remiseCmdClient = cmd.RemiseCom
      this.totalNetCmdClient = +this.totalCmdClient - +this.remiseCmdClient
      this.partAssu = cmd.PartAssu */
      this.totalNetCmdClient = +this.totalCmdClient - +this.remiseCmdClient
      this.partClient = this.totalNetCmdClient

      this.RemiseComComptoire = this.remiseCmdClient
      this.TotalFactNetComptoire = this.totalNetCmdClient
      this.PartClientComptoire = this.partClient
      this.popopVenteComptoire = false
    },
    venteAUComptoireCalculMonnaie () {
      this.Monnaie = this.MontantRecu - this.Montant
    },

    reglementCmdComptoire () {
      const payload = {

        IdOfficine: this.IdOfficine,
        IdClient: this.clientId,
        IdUser: this.IdUser,
        DateCommd: new Date(new Date(this.dateCaisse)).toISOString().split('T')[0],
        RemiseCom: this.RemiseComComptoire,
        TotalFactBrut: this.totalCmdClient,
        TotalFactNet: this.TotalFactNetComptoire,
        PartClient: this.PartClientComptoire,
        PartAssu: this.PartAssuComptoire,
        articles: this.venteComptoires,
        modeRegl: this.modeRegl,
        Montant: this.Montant,
        NumCheque: this.NumCheque,
        remarque: this.remarque
      }

      this.$vs.loading({
        type: 'sound'
      })

      this.$store.dispatch('commande_comptoire/reglementCommandeComptoire', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.IdClient = null
          this.RemiseComComptoire = null
          this.TotalFactNetComptoire = null
          this.venteComptoires = []
          this.popopReglcmdComptoire = false
          this.Montant = null
          this.NumCheque = null

          this.popopReglcmdComptoire = false

          this.$vs.loading.close()
          this.getOperationToDay()
        })
        .catch(err => {
          this.handleError(err)
        })
    },

    /* Depense */

    ChangeDateCaisse () {
      this.changeCaisseDate = !this.changeCaisseDate
    },

    /* Versement */
    makeVersementBanque () {
      const payload = {
        IdOfficine: this.IdOfficine,
        IdUser: this.IdUser,
        Coursier: this.Coursier,
        DateDepot: new Date(new Date(this.dateCaisse)).toISOString().split('T')[0],
        Banque: this.Banque,
        MontantDeposer: this.MontantDeposer,
        Observation: this.ObservationVersement,
        verCaisseDepense: this.verCaisseDepense
      }

      this.$vs.loading({
        type: 'sound'
      })

      this.$store.dispatch('versementBanque/versement', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success !',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })

          this.Banque = ''
          this.MontantDeposer = 0
          this.Coursier = ''
          this.ObservationVersement = ''
          this.verCaisseDepense = false

          this.SelectOperationOfDateSelected()
          this.$vs.loading.close()
        })
        .catch(err => {
          this.handleError(err)
        })


    },
    ValidVersement (IdVersement) {
      this.$vs.loading({
        type: 'sound'
      })
      this.$store.dispatch('versementBanque/validedVersement', IdVersement)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success !',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.$vs.loading.close()
        })
        .catch(err => {
          this.handleError(err)
        })
    },
    ArchiveVersement (IdVersement) {
      this.$vs.loading({
        type: 'sound'
      })

      this.$store.dispatch('versementBanque/ArchiveVersement', IdVersement)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.$vs.loading.close()
        })
        .catch(err => {
          this.handleError(err)
        })
    },

    /* Cloture */
    makeClotureCaisseRecette () {
      console.log('totalEncaisse: ', this.totalEncaisse)
      console.log('totalCaisse: ', this.totalCaisse)
      const ecartCaisse = this.totalEncaisse - this.totalCaisse
      console.log('ecartCaisse: ', ecartCaisse)
      if (ecartCaisse === 0) {
        const payload = {

          IdOfficine: this.IdOfficine,
          IdUser: this.IdUser,
          DateCloture: this.dateCaisse,
          MontDixMil: this.MontDixMil,
          MontCqMil: this.MontCqMil,
          MontDeuxMil: this.MontDeuxMil,
          MontMil: this.MontMil,
          MontCqCnt: this.MontCqCnt,
          MontDeuxCntCqt: this.MontDeuxCntCqt,
          MontDeuxCnt: this.MontDeuxCnt,
          MontCnt: this.MontCnt,
          MontCqt: this.MontCqt,
          MontVingtCinq: this.MontVingtCinq,
          MontDix: this.MontDix,
          MontCinq: this.MontCinq,
          TotalEncaisse: this.TotalEnCaisse,
          Observation: this.Observation,
          MontCheqRecu: this.MontantCheqRecu,
          MontCeqEmis: this.MontantCheqEmis
        }

        this.$vs.loading({
          type: 'sound'
        })

        this.$store.dispatch('billetage/makeClotureCaisseRecette', payload)
          .then(() => {
            this.handleSuccess()
          })
          .catch(err => {
            this.handleError(err)
          })

      } else {
        this.showErrorDialog(ecartCaisse)
      }
    },
    handleSuccess () {
      this.$vs.notify({
        title: 'success',
        text: 'success',
        iconPack: 'feather',
        icon: 'icon-check',
        color: 'primary'
      })

      this.getBilletageOnPeriode()
      this.popupCloture = false
      this.$vs.loading.close()
    },
    handleError (err) {
      this.$vs.loading.close()
      this.$vs.notify({
        title: 'error',
        text: `${err}`,
        iconPack: 'feather',
        icon: 'icon-error',
        color: 'danger'
      })
    },
    showErrorDialog (ecartCaisse) {
      this.popupCloture = false
      this.$vs.dialog({
        type: 'alert',
        color: 'danger',
        title: 'Error',
        text: `L'écart de votre caisse est incorrect: ${ecartCaisse}`,
        buttonAccept: 'true'
      })
    },

    getBilletageOnPeriode () {
      const payload = {
        idOfficine: this.IdOfficine,
        DateCloture: this.dateCaisse
      }

      this.$vs.loading({
        type: 'sound'
      })

      this.$store.dispatch('billetage/getBilletageOnPeriode', payload)
        .then((response) => {
          this.Observation = response.data ? (response.data.Observation ? response.data.Observation : '') : ''
          this.TotalEnCaisse = response.data ? (response.data.TotalEncaise ? response.data.TotalEncaise : 0) : 0
          this.MontDixMil = response.data ? (response.data.MontDixMil ? response.data.MontDixMil : 0) : 0
          this.MontCqMil = response.data ? (response.data.MontCqMil ? response.data.MontCqMil : 0) : 0
          this.MontDeuxMil = response.data ? (response.data.MontDeuxMil ? response.data.MontDeuxMil : 0) : 0
          this.MontMil = response.data ? (response.data.MontMil ? response.data.MontMil : 0) : 0
          this.MontCqCnt = response.data ? (response.data.MontCqCnt ? response.data.MontCqCnt : 0) : 0
          this.nbreDeuxCntCq = response.data ? (response.data.nbreDeuxCntCq ? response.data.nbreDeuxCntCq : 0) : 0
          this.MontDeuxCntCqt = response.data ? (response.data.MontDeuxCntCqt ? response.data.MontDeuxCntCqt : 0) : 0
          this.MontDeuxCnt = response.data ? (response.data.MontDeuxCnt ? response.data.MontDeuxCnt : 0) : 0
          this.MontCnt = response.data ? (response.data.MontCnt ? response.data.MontCnt : 0) : 0
          this.MontCqt = response.data ? (response.data.MontCqt ? response.data.MontCqt : 0) : 0
          this.MontVingtCinq = response.data ? (response.data.MontVingtCinq ? response.data.MontVingtCinq : 0) : 0
          this.MontDix = response.data ? (response.data.MontDix ? response.data.MontDix : 0) : 0
          this.MontCinq = response.data ? (response.data.MontCinq ? response.data.MontCinq : 0) : 0
          this.MontCqMil = response.data ? (response.data.MontCqMil ? response.data.MontCqMil : 0) : 0
          this.MontCheqRecu = response.data ? (response.data.MontCheqRecu ? response.data.MontCheqRecu : 0) : 0
          this.MontCeqEmis = response.data ? (response.data.MontCeqEmis ? response.data.MontCeqEmis : 0) : 0
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },


    resetClotureData () {
      this.TotalEnCaisse = 0
      this.nbreDixMil = 0
      this.nbreCqMil = 0
      this.nbreDeuxMil = 0
      this.nbreMil = 0
      this.nbreCqCnt = 0
      this.nbreDeuxCntCq = 0
      this.nbreDeuxCnt = 0
      this.nbreCnt = 0
      this.nbreCqt = 0
      this.nbreVingtCinq = 0
      this.nbreDix = 0
      this.nbreCinq = 0
      this.MontDixMil = 0
      this.MontCqMil = 0
      this.MontDeuxMil = 0
      this.MontMil = 0
      this.MontCqCnt = 0
      this.MontDeuxCntCq = 0
      this.MontDeuxCnt = 0
      this.MontCnt = 0
      this.MontCqt = 0
      this.MontVingtCinq = 0
      this.MontDix = 0
      this.MontCinq = 0
    },

    /* end cloture */

    /* Consultation */
    makeConsultation () {
      const payload = {
        IdOfficine: this.IdOfficine,
        IdUser: this.IdUser,
        Patient: this.Patient,
        DateConsultation: this.dateCaisse,
        MontantConsultation: this.MontantConsultation,
        ObservationConsultation: this.ObservationConsultation
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('consultation/makeConsultation', payload)
        .then(() => {

          this.SelectOperationOfDateSelected()
          this.popupConsultation = false
          this.Patient = ''
          this.MontantConsultation = null
          this.ObservationConsultation = ''
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })

    },
    SelectOperationOfDateSelected () {
      const payload = {
        selectDate: this.dateCaisse,
        Officine: this.IdOfficine
      }
      /* console.log(`dateCaisse=> ${this.dateCaisse}`)
      console.log(`IdOfficine=> ${this.IdOfficine}`) */
      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('operation/getOperationToDay', payload)
        .then(() => {

          this.getBilletageOnPeriode()
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })

    },
    getOperationToDay () {
      const payload = {
        selectDate: this.dateCaisse,
        Officine: this.IdOfficine
      }

      /* console.log(`dateCaisse=> ${this.dateCaisse}`)
      console.log(`IdOfficine=> ${this.IdOfficine}`) */

      this.$store.dispatch('operation/getOperationToDay', payload)
        .then(() => {

        })
        .catch((err) => {
          console.log(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getArticleMonture (categorie) {
      const montures = this.$store.getters['stock/getArticleByCategorieArticle'](categorie)
      return montures.length ? montures : []
    },
    getCategorieMontureId () {
      const categorie = this.$store.state.categorie_article.categorie_articles.find((c) => c.Libelle_Categ === 'Monture')
      return categorie ? categorie._id : null
    },
    getOfficines () {
      this.$store.dispatch('officine/getOfficines')
        .then(() => {

        })
        .catch((err) => {
          console.log(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getClientsOfficine (id) {
      this.$store.dispatch('client/getClientsOfficine', id)
        .then((response) => {
          console.log(response.data)
        })
        .catch((err) => {
          console.log(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },

    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
      this.IdOfficine = this.activeUserInfos.IdOfficine
      this.IdUser = this.activeUserInfos._id
      /* this.getClientsOfficine(this.IdOfficine) */
    },
    /* vente au comptoire */
    HistoriqueCommandeComptoire () {

    },
    getStockByOfficine () {
      this.$store.dispatch('stock/getStockByOfficine', this.IdOfficine)
        .then(() => {

        })
        .catch((err) => {
          console.log(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getBanqueData () {
      if (this.$store.state.banque.banques.length === 0) {
        this.$store.dispatch('banque/fetchBanques')
          .then(() => {

          })
          .catch(err => {
            console.error(err)
            this.$vs.notify({
              title: 'error',
              text: `${err}`,
              iconPack: 'feather',
              icon: 'icon-error',
              color: 'danger'
            })
          })
      }
    },
    getCategorieArticles () {
      this.$store.dispatch('categorie_article/getCategorieArticles')
        .then(() => {

        })
        .catch(err => {
          console.error(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })

    },
    getCategorieArticleById (id) {
      const categorie = this.$store.getters['categorie_article/getCategorieArticleById'](id)
      return categorie ? categorie.Abreg_Categ : ''

    },
    getTypes () {
      if (this.$store.state.type.types.length < 0) {
        this.$store.dispatch('type/getTypes')
          .then(() => {
          })
          .catch(err => {
            console.error(err)
            this.$vs.notify({
              title: 'error',
              text: `${err}`,
              iconPack: 'feather',
              icon: 'icon-error',
              color: 'danger'
            })
          })
      }
    },
    getTypeById (id) {
      const type = this.$store.getters['type/getTypeById'](id)
      return type ? type.Type_Abreg : ''

    },
    selectclientSinceClientPanel () {
      if (this.$route.params.clientId) {

        this.getCommandeOnSelectClient(this.$route.params.clientId)
        this.clientId = this.$route.params.clientId
        this.RefCmmd = this.$route.params.RefCmmd
        this.ShowCommandeDetailForRegl(this.$route.params.RefCmmd)
      }
    },
    fetchUser (userId) {
      this.$store.dispatch('userManagement/fetchUser', userId)
        .then((response) => {
          return `${response.nom} ${response.prenoms}`
        })
        .catch(err => {
          console.error(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    fetchUsers () {
      this.$store.dispatch('userManagement/fetchUsers')
        .then(() => {
        })
        .catch(err => {
          console.error(err)
          this.$vs.notify({
            title: 'error',
            text: `${err.message}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getVersementBanque () {
      this.$store.dispatch('versementBanque/fetchVersementBanqByAgence', this.IdOfficine)
        .then(() => {
        })
        .catch(err => {
          console.error(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    }
  },
  created () {
    if (!(this.$store.state.officine.officines.length > 0)) this.getOfficines()
    this.activeUserInfo()
    this.getStockByOfficine()
    if (!(this.$store.state.userManagement.users.length > 0)) this.fetchUsers()
    if (!(this.$store.state.marque.marques.length > 0)) this.getMarques()
    /* setInterval(this.getNow, 1000) */
    this.getBanqueData()
    if (!(this.$store.state.categorie_article.categorie_articles.length > 0)) this.getCategorieArticles()
    this.getTypes()
    this.selectclientSinceClientPanel()
    this.getFondDeCaisses()
    this.getOperationToDay()
    this.getBilletageOnPeriode()
    if (!(this.$store.state.versementBanque.versements.length > 0)) this.getVersementBanque()
  }
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/vuexy/components/vxAutoSuggest.scss";
.not-data-table{
  display: none;
}

.Table {
  border: 0px solid black;
}
.thTable, .tdTable {
  border: 1px solid black;
  font-size: 12px;
  padding: 10px;
}

.ChangeDate {
  cursor: pointer
}

.ChangeDate:hover {
  background-color: black;
  color: white;
}

.btnTextColor {
  color: black;
}
.bg-darkgrey {
  background-color: #818181;
}

.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 45vw;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}
</style>

